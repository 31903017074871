<template>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <Breadcrumb :datas='breadcrumb_data' />
        <!--begin::Entry-->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <!--begin::Profile Personal Information-->
                <div class="d-flex flex-row">
                    <!--begin::Aside-->
                    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
                        <!--begin::Profile Card-->
                        <div class="card card-custom card-stretch">
                            <!--begin::Body-->
                            <div class="card-body pt-6 mt-6">
                                <!--begin::User-->
                                <div class="d-flex align-items-center">
                                    <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                        <div class="symbol-label">{{ personnel.id }}</div>
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ personnel.name }} {{ personnel.surname }}</a>
                                        <!-- <div class="text-muted">{{ personnel.departmentName }}</div> -->
                                    </div>
                                </div>
                                <!--end::User-->
                                <!--begin::Contact-->
                                <div class="py-9">
                                    <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                                        <span class="font-weight-bold mr-2">{{ $t('commons.department') }}:</span>
                                        <a href="#" class="text-muted text-hover-primary">{{ personnel.departmentName }}</a>
                                    </div> -->
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <span class="font-weight-bold mr-2">{{ $t('commons.e_mail') }}:</span>
                                        <span class="text-muted">{{ personnel.email }}</span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="font-weight-bold mr-2">{{ $t('commons.mobile_phone') }}:</span>
                                        <span class="text-muted">{{ personnel.phone }}</span>
                                    </div>
                                </div>
                                <!--end::Contact-->
                                <!--begin::Nav-->
                                <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
                                    <div class="navi-item mb-2">
                                        <a href="javascript:void(0)" @click="setActiveTab('overview')" class="navi-link py-4" :class="{'active': activeTab === 'overview'}">
                                            <span class="navi-icon mr-2">
                                                <span class="svg-icon">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" fill="#000000" fill-rule="nonzero" />
                                                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-size-lg">{{ $t('employees.overview') }}</span>
                                        </a>
                                    </div>
                                    <div class="navi-item mb-2">
                                        <a href="javascript:void(0)" @click="setActiveTab('staff-info')" class="navi-link py-4" :class="{'active': activeTab === 'staff-info'}">
                                            <span class="navi-icon mr-2">
                                                <span class="svg-icon">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-size-lg">{{ $t('employees.staff_info') }}</span>
                                        </a>
                                    </div>
                                    <div class="navi-item mb-2">
                                        <a href="javascript:void(0)" @click="setActiveTab('role-authority')" class="navi-link py-4" :class="{'active': activeTab === 'role-authority'}">
                                            <span class="navi-icon mr-2">
                                                <span class="svg-icon">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Shield-user.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z" fill="#000000" opacity="0.3" />
                                                            <path d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z" fill="#000000" opacity="0.3" />
                                                            <path d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z" fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text font-size-lg">{{ $t('employees.role_authority_management') }}</span>
                                        </a>
                                    </div>
                                    <div class="navi-item mb-2">
                                        <a href="javascript:void(0)" @click="setActiveTab('transaction-history')" class="navi-link py-4" :class="{'active': activeTab === 'transaction-history'}" data-toggle="tooltip" title="Coming soon..." data-placement="right">
                                            <span class="navi-icon mr-2">
                                                <span class="svg-icon">
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/Text/Article.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5" />
                                                            <path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L12.5,10 C13.3284271,10 14,10.6715729 14,11.5 C14,12.3284271 13.3284271,13 12.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z" fill="#000000" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </span>
                                            <span class="navi-text">{{ $t('employees.transaction_history') }}</span>
                                        </a>
                                    </div>
                                </div>
                                <!--end::Nav-->
                            </div>
                            <!--end::Body-->
                        </div>
                        <!--end::Profile Card-->
                    </div>
                    <!--end::Aside-->
                    <!--begin::Content-->
                    <div class="flex-row-fluid ml-lg-8">
                        <!--begin::Card overview-->
                        <div class="card card-custom card-stretch" v-if="activeTab === 'overview'">
                            <!--begin::Header-->
                            <div class="card-header py-3">
                                <div class="card-title">
                                    <h3 class="card-label font-weight-bolder text-dark">{{ $t('employees.overview') }}</h3>
                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <p>{{ $t('employees.overview') }}</p>
                            </div>
                            <!--end::Form-->
                        </div>

                        <!--begin::Card staff info-->
                        <div class="card card-custom card-stretch" v-if="activeTab === 'staff-info'">
                            <!--begin::Header-->
                            <div class="card-header py-3">
                                <div class="card-title">
                                    <h3 class="card-label font-weight-bolder text-dark">{{ $t('employees.staff_info') }}</h3>
                                </div>
                            </div>
                            <!--end::Header-->
                            <!--begin::Form-->
                            <form class="form">
                                <!--begin::Body-->
                                <div class="card-body">
                                    <div class="row">
                                        <label class="col-xl-3"></label>
                                        <div class="col-lg-9 col-xl-6">
                                            <h5 class="font-weight-bold mb-6">{{ $t('employees.staff_info') }}</h5>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.first_name') }}</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input disabled class="form-control form-control-lg form-control-solid" type="text" :value="personnel.name" />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.last_name') }}</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input disabled class="form-control form-control-lg form-control-solid" type="text" :value="personnel.surname" />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.department') }}</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <input disabled class="form-control form-control-lg form-control-solid" type="text" :value="personnel.departmentName" />
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <label class="col-xl-3"></label>
                                        <div class="col-lg-9 col-xl-6">
                                            <h5 class="font-weight-bold mt-10 mb-6">{{ $t('employees.contact_info') }}</h5>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.mobile_phone') }}</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <div class="input-group input-group-lg input-group-solid">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="la la-phone"></i>
                                                    </span>
                                                </div>
                                                <input type="text" disabled class="form-control form-control-lg form-control-solid" :value="personnel.phone" placeholder="Phone" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('commons.e_mail') }}</label>
                                        <div class="col-lg-9 col-xl-6">
                                            <div class="input-group input-group-lg input-group-solid">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="la la-at"></i>
                                                    </span>
                                                </div>
                                                <input type="text" disabled class="form-control form-control-lg form-control-solid" :value="personnel.email" placeholder="Email" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Body-->
                            </form>
                            <!--end::Form-->
                        </div>

                        <!--begin::Card role-authority-->
                        <div class="card card-custom card-stretch" v-if="activeTab === 'role-authority'">
                            <!--begin::Header-->
                            <div class="card-header py-3">
                                <div class="card-title">
                                    <h3 class="card-label font-weight-bolder text-dark">{{ $t('employees.role_authority_management') }}</h3>
                                </div>
                            </div>
                            <!--end::Header-->
                            
                            <div class="card-body">
                                <div class="spinner spinner-info spinner-center" v-if="rolesLoading"></div>
                                <form class="form" v-else>
                                    <div class="form-group">
                                        <div class="checkbox-inline">
                                            <label class="checkbox checkbox-lg">
                                                <input type="checkbox" :checked="areRolesCheckedAll(authorityRoles.map((val) => val.id))" name="roles_all" @change.stop="onRoleCheckAllChange(authorityRoles.map((val) => val.id))" />
                                                <span></span> {{ $t('roles.roles') }}
                                            </label>
                                        </div>
                                        <div class="checkbox-list">
                                            <div v-for="(role, idx) in authorityRoles" :key="idx" class="my-1">    
                                                <label class="checkbox">
                                                    <input type="checkbox" :name="`role-${role.id}`" :checked="isRoleChecked(role.id)" @change.stop="onRoleChange(role.id)" />
                                                    <span></span>{{role.name}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 row col-sm-12 justify-content-center mt-2">
                                        <form-submit-btn
                                            :btnClass="'btn btn-primary w-50'"
                                            @click="submitRoles"
                                            :text="$t('commons.save')"
                                            :isSubmitting="isRolesFormSubmitting"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!--begin::Card transaction-history-->
                        <div class="card card-custom card-stretch" v-if="activeTab === 'transaction-history'">
                            <!--begin::Header-->
                            <div class="card-header py-3">
                                <div class="card-title">
                                    <h3 class="card-label font-weight-bolder text-dark">{{ $t('employees.transaction_history') }}</h3>
                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="card-body">
                                <p>{{ $t('employees.transaction_history') }}</p>
                            </div>
                            
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Profile Personal Information-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Entry-->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'

export default {
  name: 'Details',
  data() {
    return {
      employeeId: this.$route.params.id,
      activeTab: "overview",
      rolesLoading: false,
      isRolesFormSubmitting: false,
      rolesForm: {
          roles: []
      }
    }
  },
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapState({
      user: state => state.customer.user,
      personnel: state => state.employees.selected_employees,
      checkedAuthorityRoles: state => state.employees.checked_authority_roles,
      authorityRoles: state => state.employees.authority_roles,
    }),
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [
          { title: this.$t('employees.title'), route: '/employees' },
          this.$t('commons.details')
        ]
      }
    }
  },
  methods: {
    ...mapActions('customer', ['GET_USER']),
    ...mapActions('employees', ['GET_EMPLOYESS_BY_ID', 'GET_EMPLOYEE_AUTHORITY_ROLES', 'POST_EMPLOYEE_AUTHORITY_ROLES']),
    setActiveTab(tab) {
        this.activeTab = tab;
        if(tab === 'role-authority') {
            this.GET_EMPLOYEE_AUTHORITY_ROLES(this.$route.params.id)
                .then(() => {
                    console.log(this.authorityRoles, this.checkedAuthorityRoles)
                    this.rolesForm.roles = this.checkedAuthorityRoles;
                })
        }
    },
    isRoleChecked(roleId) {
        return this.rolesForm.roles.some(role => roleId.toString() === role.toString());
    },
    areRolesCheckedAll(items) {
      return items.every((i) => this.rolesForm.roles.includes(i.toString()));
    },
    onRoleChange(roleId) {
        if(this.rolesForm.roles.some(val => val.toString() === roleId.toString())) {
            this.rolesForm.roles = this.rolesForm.roles.filter(val => val.toString() !== roleId.toString())
            return
        }
        this.rolesForm.roles.push(roleId.toString());
    },
    onRoleCheckAllChange(roleIds) {
        if(this.areRolesCheckedAll(roleIds)) {
            this.rolesForm.roles = [];
            return;
        }
        roleIds.forEach((roleId) => {
            this.rolesForm.roles.push(roleId.toString())
        });
    },
    submitRoles() {
        this.isRolesFormSubmitting = true
        this.POST_EMPLOYEE_AUTHORITY_ROLES({
            id: this.$route.params.id,
            roles: this.rolesForm.roles,
        })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
            this.isRolesFormSubmitting = false;
            });
    }
  },
  created() {
    this.rolesLoading = true;
    this.GET_EMPLOYESS_BY_ID(this.employeeId)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
            this.rolesLoading = false;
        })
  }
}
</script>
